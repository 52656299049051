<template>
  <v-form
    ref="searchForm"
    action="/search"
  >
    <div

      class="search-bar d-flex p-relative"
    >
      <v-combobox
        v-model="search"
        name="query"
        class=""
        :placeholder="$t('Entrez le mot-clé ici . . .')"
        filled
        rounded
        dense
        :multiple="false"
        :loading="loading"
        prepend-inner-icon="mdi-magnify"
        :items="items"
        :search-input.sync="search"
        @keydown.enter="$refs.searchForm.submit()"
      >
        <template v-slot:no-data>
          <v-list-item>
            <span class="subheading">{{ search && search.length > 0?(loading?$t('Chargement...') : $t('Pas de données disponibles pour') + " '" + search + "'"): $t('Tapez un terme de recherche dans le champ pour effectuer une recherche.') }}</span>
          </v-list-item>
        </template>
        <template v-slot:item="{item}">
          <v-list-item
            :to="(searchType.value === 'articles'?`/products/${item.slug}`:`/shops/${item.id}`)"
            @click="search = ''"
          >
            <v-list-item-avatar>
              <v-img
                :src="searchType.value === 'articles'?item.image:item.avatar_thumb || '@/assets/images/logo-mini.png'"
                lazy-src="@/assets/images/logo-mini.png"
              />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title
                class="text-truncate"
                v-html="searchType.value === 'articles'?`${item.name}`:item.pseudo"
              />
              <v-list-item-subtitle
                v-if="item.user && item.user.pseudo"
                class="text-truncate"
                v-html="`${item.user.pseudo}`"
              />
            </v-list-item-content>
            <v-list-item-action v-if="searchType.value === 'articles'">
              <v-list-item-action-text class="text-body-1">
                <product-price :product="item" />
              </v-list-item-action-text>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-combobox>
      <div class="search-bar-dropdown">
        <v-menu
          v-if="searchType.value === 'articles'"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="white"
              class="text-capitalize dropdown"
              v-bind="attrs"
              v-on="on"
            >
              {{ selectedCategory?selectedCategory.name : $t('Catégories') }}
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in $store.state.categories"
              :key="index"
              @click="cancelsearch;selectedCategory = item"
            >
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="white"
              class="text-capitalize dropdown"
              v-bind="attrs"
              v-on="on"
            >
              {{ searchType ? searchType.name : $t('Articles') }}
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in searchTypes"
              :key="index"
              @click="searchType = item;find"
            >
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
  </v-form>
</template>

<script>
  import { i18n } from '@/plugins/i18n'

  import { mapState, mapMutations } from 'vuex'
  import ProductPrice from "@/components/products/ProductPrice.vue";

  export default {
    name: 'SearchForm',
    components: {ProductPrice},

    props: {
    },
    data () {
      return {
        searchForm: null,
        selectedCategory: null,
        searchType: { name: i18n.t('Articles'), value: 'articles' },
        cartCount: 0,
        colorNav: false,
        dialog: false,
        isToggleNavbar: false,
        items: [],
        searchTypes: [
          { name: i18n.t('Articles'), value: 'articles' },
          { name: i18n.t('Membres'), value: 'members' },
        ],
        loading: false,
        search: '',
        categorysearch: 'articles',
        categoryid: '0',
        category: {},
        el: null,
        resultdatas: [
          { value: 1, text: 'Product' },
          { value: 2, text: 'Product' },
          { value: 3, text: 'Product' },
        ],
        products: [],
        members: [],
        timer: null,
      }
    },
    computed: {
      ...mapState({
        user: 'user',
        // totalItems: state => state.withdraws.length,
      }),
    },
    watch: {
      search (val, prev) {
        if (val && val.length < 3) return

        if (this.timer) clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.find()
        }, 1200)
      },
      searchType: {
        handler (val) {
          console.log(val)
          if (this.search && this.search.length > 2) {
            this.find({ refresh: true })
          }
        },
        deep: true,
      },
    },
    mounted () {
      this.search = this.$route.query.query || ''
    },
    methods: {
      ...mapMutations([
        'pushItem', // map `this.increment()` to `this.$store.commit('increment')`
        'removeItem', // map `this.increment()` to `this.$store.commit('increment')`

        // `mapMutations` also supports payloads:
        'setValue', // map `this.incrementBy(amount)` to `this.$store.commit('incrementBy', amount)`
      ]),
      cancelsearch () {
        console.log('click ')
        this.search = ''
        this.resultdatas = []
        this.products = []
        this.members = []
      },
      find (option) {
        /* if (e.keyCode === 13) { // || product.id
          return
        } */

        if (this.search && this.search.length >= 3) {
          if (this.searchType.value === 'articles') { this.findproduct(option) } else { this.findmember(option) }
        } else {
          this.products = []
          this.members = []
          this.resultdatas = []
          this.items = []
        }
      },
      findproduct (option) {
        /* if (option == null && this.items.length) {
          this.items = this.filterrow(this.search, this.items)
          return
        } */
        // else
        this.loading = true
        var cat = this.selectedCategory ? this.selectedCategory.id : 0
        axios.get('/products/search/' + cat + '/' + this.search)//
          .then((response) => {
            console.log(response)
            this.items = response.data.data
            this.products = response.data.data
          }).catch(function (error) {
            console.log(error)
          }).then(() => {
            this.loading = false
          })
      },
      findmember (option) {
        /* if (option == null && this.items.length) {
          this.items = this.filterrow(this.search, this.items)
          return
        } */
        this.loading = true
        // else
        axios.get('/users/search/' + this.search)//
          .then((response) => {
            console.log(response)
            this.items = response.data.data
            this.members = response.data.data
          }).catch(function (error) {
            console.log(error)
          }).then(() => {
            this.loading = false
          })
      },

      filterrow (value, dataarray) {
        var filter; var filtered = []; var tr; var td; var i; var data

        console.log(dataarray)
        filter = value.toUpperCase()

        for (i = 0; i < dataarray.length; i++) {
          data = dataarray[i]
          if (data.name.toUpperCase().indexOf(filter) > -1) {
            filtered.push(data)
          }
        }
        return filtered
      },
      toast (message, color) {
        this.isSnackbarBottomVisible = true
        this.snackBarColor = color
        this.snackBarMessage = message
      },
    },
  }
</script>

<style lang="scss" scoped>
$z-index-sub-topbar: 2;
$md: 959px;
$z-99: 99;
.z-999 {
  z-index: 999;
}

.v-list-item {
  min-height: 34px;
}
.top-bar {
  height: 40px;
  display: flex;
  align-items: center;
}
.sale-page-one {
  .sub-topbar {
    &.v-app-bar--is-scrolled {
      position: relative;
      .dropdown-ecommerce {
        display: none;
      }
    }
  }

  .navbar {
    &.v-app-bar--is-scrolled {
      top: 0;
      margin-bottom: 0px;
    }
  }
}
.sale-page-two {
  .sub-topbar {
    &.v-app-bar--is-scrolled {
      position: relative;
      .dropdown-ecommerce {
        display: none;
      }
    }
  }

  .navbar {
    &.v-app-bar--is-scrolled {
      top: 0;
      margin-bottom: 0px;
    }
  }
}
.sub-topbar {
  position: relative;
  box-shadow: none !important;
  width: 100%;
  height: 80px !important;
  z-index: $z-99;
  .dropdown-ecommerce {
    display: none;
  }
  &.v-app-bar--fixed {
    position: unset;
  }
  // z-index: $z-index-sub-topbar;

  &.v-app-bar--is-scrolled {
    position: fixed;
    top: 0;
    box-shadow: none !important;
    .dropdown-ecommerce {
      display: block;
    }
  }
  @media (max-width: $md) {
  }
}

.navbar {
  height: 60px;
  width: 100%;
  z-index: 2;
  box-shadow: rgb(43 52 69 / 10%) 0px 16px 16px -16px !important;
  &.v-app-bar--is-scrolled {
    position: relative;
    top: 64px;
    margin-bottom: 64px;
    box-shadow: rgb(43 52 69 / 10%) 0px 16px 16px -16px !important;
    // box-shadow: none !important;
  }
  @media (max-width: 992px) {
    display: none;
  }
}
.search-bar {
  ::v-deep .v-input__slot {
    border: 1px solid rgb(218, 225, 231);
    background-color: #fff !important;

  }
  .search-bar-dropdown {

    height: 39px;
    position: absolute;
    top: 20px;
    transform: translateY(-50%);
    right: 2px;

    @media (max-width: $md) {
      display: none;
    }
    .dropdown {
      height: 39px;
      border-radius: 0px;
      border-left: 1px solid rgb(218, 225, 231) !important;
      &:last-child {
        border-radius: 22px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-left: 1px solid rgb(218, 225, 231) !important;
      }
      box-shadow: none !important;
    }
  }
  ::v-deep .v-text-field__details {
    display: none;
  }
}

// toggleNavbarButton
.navbar-toggle-dropdown {
  z-index: 98;
  display: none;
  &.open {
    display: block;
    top: 64px;
  }
}
.v-hidden {
  visibility: hidden;
}
.empty-cart-sidebar {
  height: 80vh
}
</style>
