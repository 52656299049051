<template>
  <div
    :class="[
      { 'sale-page-one': $route.path == '/sale-page-one' },
      { 'sale-page-two': $route.path == '/sale-page-two' },
    ]"
  >
    <div
      class="secondary white--text top-bar"
      :class="{ 'd-none': $route.path == '/home-two' }"
    >
      <v-container>
        <div class="d-flex justify-space-between align-center">
          <v-toolbar-title>
            <v-img
              class="me-2 logo d-md-none d-block"
              width="98"
              src="@/assets/images/logo.png"
              alt=""
            />
            <div class="mr-2 d-md-block d-none">
              <v-icon
                class="mr-2 white--text"
                small
              >
                mdi-phone-outline
              </v-icon>
              <a
                :href="'tel:' + $store.state.settings.tel"
                class="text-sm mr-5 text-decoration-none white--text"
              >{{ $store.state.settings.tel }}</a>
              <router-link
                to="/contact"
                class="text-decoration-none"
              >
                <v-icon
                  class="mr-2 white--text"
                  small
                >
                  mdi-email
                </v-icon>
                <span class="text-sm white--text">{{ $t("Contact") }}</span>
              </router-link>
            </div>
          </v-toolbar-title>
          <v-toolbar-title>
            <!--            <span
              class="white&#45;&#45;text text-sm mr-5 d-md-inline-block d-none"
            >Theme FAQ's</span>-->
            <router-link
              to="/wishlist"
              class="white--text text-sm mr-5 text-decoration-none"
            >
              <span class="d-md-inline-block d-none text-sm ">{{ $t("Mes Coups de Cœur") }}</span> <v-icon
                :size="$vuetify.breakpoint.mdAndUp?20:30"
                color="primary"
              >
                mdi-heart
              </v-icon>
            </router-link>
            <app-bar-notifications />
            <app-bar-i18n />
          </v-toolbar-title>
        </div>
      </v-container>
    </div>
    <v-app-bar
      height="72"
      app
      scroll-off-screen
      class="sub-topbar"
      color="white py-1"
    >
      <v-container>
        <div class="d-flex justify-space-between align-center">
          <v-toolbar-title class="d-flex align-center">
            <router-link to="/">
              <v-img
                class="me-2 logo d-md-block d-none"
                width="150"
                src="@/assets/images/logo.png"
                alt=""
              />
            </router-link>
            <div class="dropdown-ecommerce">
              <header-navbar />
            </div>
          </v-toolbar-title>
          <v-col
            cols="12"
            md="7"
          >
            <div
              v-if="$route.path == '/home-two'"
              class="search-bar d-flex p-relative"
            >
              <v-text-field
                class=""
                placeholder="Searching For"
                filled
                rounded
                hide-details
                dense
                prepend-inner-icon="mdi-magnify"
              />
              <v-btn
                color="primary"
                class="text-capitalize search-bar-dropdown px-10 font-600"
              >
                Search
              </v-btn>
            </div>

            <search-form v-else />
          </v-col>
          <div class="">
            <div class="d-md-block d-none">
              <v-menu
                v-if="login"
                offset-y
                left
                min-width="250"
                :elevation="$vuetify.theme.dark ? 9 : 8"
                content-class="list-style notification-menu-content"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    elevation="0"
                    fab
                    small
                    color="grey lighten-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-account</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <div
                    class="box-sidebar pb-3 shadow-sm"
                  >
                    <profile-menu />
                  </div>
                  <!--                  <v-list class="py-0">
                    &lt;!&ndash; Header &ndash;&gt;
                    <v-list-item
                      class="d-flex"
                      link
                    >
                      <div class="d-flex align-center justify-space-between flex-grow-1">
                        <span class="font-weight-semibold">{{ user.name }}</span>
                      </div>
                    </v-list-item>
                    <v-divider />

                    <v-list-item
                      to="/profile"
                      link
                    >
                      <v-list-item-avatar>
                        <v-icon x-small>mdi-account</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content class="d-block">
                        <v-list-item-title class="text-sm font-weight-semibold">
                          {{ $t('Mon profil') }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider />
                    <v-list-item
                      to="/notifications"
                      link
                    >

                      <v-list-item-avatar>
                        <v-icon x-small>mdi-bell</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content class="d-block">
                        <v-list-item-title class="text-sm font-weight-semibold">
                          {{ $t('Notifications') }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider />
                    <v-list-item
                      to="/offers"
                      link
                    >
                      <v-list-item-avatar>
                        <v-icon x-small>mdi-account</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content class="d-block">
                        <v-list-item-title class="text-sm font-weight-semibold">
                          {{ $t('Mes offres') }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider />
                    <v-list-item
                      to="/orders"
                      link
                    >
                      <v-list-item-content class="d-block">
                        <v-list-item-title class="text-sm font-weight-semibold">
                          {{ $t('Mes commandes') }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider />
                    <v-list-item
                      to="/orders"
                      link
                    >
                      <v-list-item-content class="d-block">
                        <v-list-item-title class="text-sm font-weight-semibold">
                          {{ $t('Mes ventes') }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider />
                    <v-list-item
                      to="/products"
                      link
                    >
                      <v-list-item-content class="d-block">
                        <v-list-item-title class="text-sm font-weight-semibold">
                          {{ $t('Mes produits') }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider />
                    <v-list-item
                      to="/user/packages"
                      link
                    >
                      <v-list-item-content class="d-block">
                        <v-list-item-title class="text-sm font-weight-semibold">
                          {{ $t('Colis à recevoir') }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider />
                    <v-list-item
                      to="/seller/packages"
                      link
                    >
                      <v-list-item-content class="d-block">
                        <v-list-item-title class="text-sm font-weight-semibold">
                          {{ $t('Colis à expédier') }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider />
                    <v-list-item
                      to="/promotions"
                      link
                    >
                      <v-list-item-content class="d-block">
                        <v-list-item-title class="text-sm font-weight-semibold">
                          {{ $t('Promotions') }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider />
                    <v-list-item
                      to="/transporteurs"
                      link
                    >
                      <v-list-item-content class="d-block">
                        <v-list-item-title class="text-sm font-weight-semibold">
                          {{ $t('Transporteurs') }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider />
                    <v-list-item
                      link
                      @click="$store.commit('logout')"
                    >
                      <v-list-item-content class="d-block">
                        <v-list-item-title class="text-sm font-weight-semibold red&#45;&#45;text">
                          {{ $t('Déconnexion') }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>-->
                </v-card>
              </v-menu>
              <connect />
              <!-- cartCount  -->
              <v-badge
                bordered
                color="error"
                :content="getCartProducts.length"
                :value="getCartProducts.length > 0"
                overlap
              >
                <v-btn
                  elevation="0"
                  fab
                  color="grey lighten-2"
                  class="ml-3"
                  small
                  :disabled="getCartProducts.length < 1"
                  @click="drawer = true"
                >
                  <v-icon color="">
                    mdi-cart
                  </v-icon>
                </v-btn>
              </v-badge>
            </div>
          </div>
        </div>
      </v-container>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
      right
      width="320"
      class="z-999"
    >
      <div class="secondary-siebar-content">
        <v-list-item>
          <v-list-item-avatar class="mr-0">
            <v-icon color="">
              mdi-shopping-outline
            </v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="">
              {{ getCartProducts.length }} {{ $t("Articles") }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              icon
              color=""
              @click="drawer = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider />
        <div v-if="getCartProducts.length >=1">
          <div
            v-for="(n, index) in getCartProducts"
            :key="index"
          >
            <div
              class="cart-item d-flex justify-space-between align-center px-2 py-3"
            >
              <div class="d-flex flex-column align-center">
                <v-btn
                  class="mx-2"
                  fab
                  outlined
                  dark
                  x-small
                  color="primary"
                  @click="addCartx(n)"
                >
                  <v-icon dark>
                    mdi-plus
                  </v-icon>
                </v-btn>
                <span>{{ n.qty }}</span>
                <v-btn
                  class="mx-2"
                  fab
                  outlined
                  dark
                  x-small
                  color="primary"
                  @click="removeCartx(n)"
                >
                  <v-icon dark>
                    mdi-minus
                  </v-icon>
                </v-btn>
              </div>
              <v-avatar
                tile
                size="76"
              >
                <img
                  :src="n.image"
                  alt=""
                >
              </v-avatar>
              <div class="col-5">
                <h5 class="text-truncate">
                  {{ n.title || n.name }}
                </h5>
                <p class="text-tiny">
                  &euro;{{ n.price_without_tax || (n.product ?n.product.price_without_tax:'-') }} x {{ n.qty }}
                </p>
                <h5 class="primary--text">
                  &euro;{{ (n.total || 0 ).toFixed(2) }}
                </h5>
              </div>
            </div>
            <v-divider />
          </div>
        </div>
        <div v-else>
          <div class="empty-cart-sidebar d-flex flex-column justify-center align-center mt-10">
            <v-avatar
              size="90"
              tile
              class="mb-8"
            >
              <img
                src="@/assets/images/empty-cart.png"
                alt=""
              >
            </v-avatar>
            <p class="grey--text text--darken-2 px-5 mx-10 text-center ">
              {{ $t("Your shopping bag is empty.Start shopping") }}
            </p>
          </div>
        </div>
      </div>
      <template
        v-if="getCartProducts.length >=1"
        v-slot:append
      >
        <div class="pa-2">
          <v-btn
            to="/checkout"
            class="text-capitalize mb-3"
            block
            color="primary"
          >
            {{ $t("Commander") }} (&euro;{{ cartTotal.toFixed(2) }})
          </v-btn>
          <v-btn
            to="/cart"
            class="text-cappitalise"
            outlined
            block
            color="primary"
          >
            {{ $t("Voir le pannier") }}
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <v-navigation-drawer
      v-model="$store.state.chatDrawer"
      fixed
      temporary
      right
      :width="$vuetify.breakpoint.smAndDown?'100%':'400'"
      class="z-999"
    >
      <div v-if="$store.state.chatDrawer" class="secondary-siebar-content h-100 ">
        <v-list-item v-if="$store.state.chat" class="flex-shrink-1">
          <v-list-item-avatar class="mr-0">
            <v-img v-if="$store.state.chat && $store.state.chat.image" :src="$store.state.chat.image || ''" />
            <v-icon v-else color="">
              mdi-chat
            </v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="">
              {{ $store.state.chat.title || 'Chat'}}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              icon
              color=""
              @click="$store.state.chatDrawer = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider />
        <div style="height: 93%; max-height: 93%; overflow-y: hidden">
          <chat />
        </div>
      </div>
<!--      <template
        v-if="getCartProducts.length >=1"
        v-slot:append
      >
        <div class="pa-2">
          <v-btn
            to="/checkout"
            class="text-capitalize mb-3"
            block
            color="primary"
          >
            {{ $t("Commander") }} (&euro;{{ cartTotal.toFixed(2) }})
          </v-btn>
          <v-btn
            to="/cart"
            class="text-cappitalise"
            outlined
            block
            color="primary"
          >
            {{ $t("Voir le pannier") }}
          </v-btn>
        </div>
      </template>-->
    </v-navigation-drawer>
    <v-app-bar
      class="navbar white"
      :class="{ 'd-none': $route.path == '/sale-page-two' }"
    >
      <div class="container">
        <div class="d-flex justify-space-between align-center">
          <v-btn
            v-on-clickaway="away"
            elevation=""
            color="grey lighten-2"
            class="text-capitalize"
            :class="{ 'v-hidden': $route.path == '/home-two' }"
            @click="toggleNavbar"
          >
            <v-icon left>
              mdi-view-dashboard
            </v-icon>
            {{ $t("Categories") }}
            <v-icon right>
              mdi-chevron-down
            </v-icon>
          </v-btn>
          <div
            id="navbar-toggle-dropdown"
            class="navbar-toggle-dropdown p-absolute"
            :class="{ open: isToggleNavbar }"
          >
            <navbar />
          </div>
          <div>
            <ul class="navbar-nav navigation-navbar d-flex flex-row align-center">
              <li class="nav-item me-3 pa-3">
                <router-link
                  class="nav-link"
                  to="/"
                >
                  <p class="mb-0">
                    {{ $t("Accueil") }}
                  </p>
                </router-link>
              </li>
              <li class="nav-item me-3 pa-3">
                <router-link
                  class="nav-link"
                  to="/about-us"
                >
                  <p class="mb-0">
                    {{ $t("A Propos") }}
                  </p>
                </router-link>
              </li>
              <li class="nav-item me-3 pa-3">
                <router-link
                  class="nav-link"
                  to="/contact"
                >
                  <p class="mb-0">
                    {{ $t("Contact") }}
                  </p>
                </router-link>
              </li>
              <li class="nav-item me-3 d-flex pa-3 ">
                <v-btn
                  color="primary"
                  :to="createUrl"
                >
                  <v-icon
                    color="white"
                    class="mr-1"
                  >
                    mdi-plus
                  </v-icon>
                  <span>{{ $t("Vendre Un Produit") }}</span>
                </v-btn>
                <!--                <router-link
                  class="nav-link "
                  to="/create-product"
                >
                  <v-icon
                    color="white"
                    class="mr-1"
                  >
                    mdi-plus
                  </v-icon>
                  <span>{{ $t("Vendre Un Produit") }}</span>
                </router-link>-->
              </li>

              <!--              <li class="nav-item me-3">
                <a
                  class="nav-link active"
                  href="#"
                >Home</a>

                <ul>
                  <li>
                    <router-link to="/">
                      <p class="mb-0">
                        Homepage One
                      </p>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/home-two">
                      <p class="mb-0">
                        Grocery
                      </p>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/home-three">
                      <p class="mb-0">
                        Niche Market 1
                      </p>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/home-four">
                      <p class="mb-0">
                        Niche Market 2
                      </p>
                    </router-link>
                  </li>
                </ul>
              </li>
              <li class="nav-item me-3">
                <a
                  class="nav-link"
                  href="#"
                >Pages</a>
                <ul>
                  <li>
                    <a
                      href="#"
                      class="d-flex justify-space-between align-center"
                    >
                      <p class="mb-0">Sale Page</p>
                      <v-icon>mdi-menu-right</v-icon>
                    </a>
                    <div class="navbar-submenu">
                      <ul>
                        <li>
                          <router-link to="/sale-page-one">
                            <p class="mb-0">
                              Version 1
                            </p>
                          </router-link>
                        </li>
                        <li>
                          <router-link to="/sale-page-two">
                            <p class="mb-0">
                              Version 2
                            </p>
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <a
                      href="#"
                      class="d-flex justify-space-between align-center"
                    >
                      <p class="mb-0">Vendor</p>
                      <v-icon>mdi-menu-right</v-icon>
                    </a>
                    <div class="navbar-submenu">
                      <ul>
                        <li>
                          <router-link to="/all-vendors">
                            <p class="mb-0">
                              All Vendors
                            </p>
                          </router-link>
                        </li>
                        <li>
                          <router-link to="/vendor-store">
                            <p class="mb-0">
                              Vendor Store
                            </p>
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <a
                      href="#"
                      class="d-flex justify-space-between align-center"
                    >
                      <p class="mb-0">Product</p>
                      <v-icon>mdi-menu-right</v-icon>
                    </a>
                    <div class="navbar-submenu">
                      <ul>
                        <li>
                          <router-link to="/search-product">
                            <p class="mb-0">
                              Product Grid/Search
                            </p>
                          </router-link>
                        </li>
                        <li>
                          <router-link to="/single-product">
                            <p class="mb-0">
                              Product List/Search
                            </p>
                          </router-link>
                        </li>
                        <li>
                          <router-link to="/cart">
                            <p class="mb-0">
                              Cart
                            </p>
                          </router-link>
                        </li>
                        <li>
                          <router-link to="/checkout-alternative">
                            <p class="mb-0">
                              Checkout Alternative
                            </p>
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </li>
              <li class="nav-item me-3">
                <a
                  class="nav-link"
                  href="#"
                >User Account</a>
                <ul>
                  <li>
                    <a
                      href="#"
                      class="d-flex justify-space-between align-center"
                    >
                      <p class="mb-0">Address</p>
                      <v-icon>mdi-menu-right</v-icon>
                    </a>
                    <div class="navbar-submenu">
                      <ul>
                        <li>
                          <router-link to="/add-address">
                            <p class="mb-0">
                              Add Address
                            </p>
                          </router-link>
                        </li>
                        <li>
                          <router-link to="/list-address">
                            <p class="mb-0">
                              List Address
                            </p>
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <a
                      href="#"
                      class="d-flex justify-space-between align-center"
                    >
                      <p class="mb-0">Orders</p>
                      <v-icon>mdi-menu-right</v-icon>
                    </a>
                    <div class="navbar-submenu">
                      <ul>
                        <li>
                          <router-link to="/order-list">
                            <p class="mb-0">
                              Order List
                            </p>
                          </router-link>
                        </li>
                        <li>
                          <router-link to="/order-details">
                            <p class="mb-0">
                              Order Details
                            </p>
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <a
                      href="#"
                      class="d-flex justify-space-between align-center"
                    >
                      <p class="mb-0">Profile</p>
                      <v-icon>mdi-menu-right</v-icon>
                    </a>
                    <div class="navbar-submenu">
                      <ul>
                        <li>
                          <router-link to="/view-profile">
                            <p class="mb-0">
                              View Profile
                            </p>
                          </router-link>
                        </li>
                        <li>
                          <router-link to="/edit-profile">
                            <p class="mb-0">
                              Edit Profile
                            </p>
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <a
                      href="#"
                      class="d-flex justify-space-between align-center"
                    >
                      <p class="mb-0">Support Tickets</p>
                      <v-icon>mdi-menu-right</v-icon>
                    </a>
                    <div class="navbar-submenu">
                      <ul>
                        <li>
                          <router-link to="/all-tickets">
                            <p class="mb-0">
                              All Tickets
                            </p>
                          </router-link>
                        </li>
                        <li>
                          <router-link to="/ticket-details">
                            <p class="mb-0">
                              Ticket Details
                            </p>
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <router-link to="/wishlist">
                      <p class="mb-0">
                        Wishlist
                      </p>
                    </router-link>
                  </li>
                </ul>
              </li>
              <li class="nav-item me-3">
                <a
                  class="nav-link"
                  href="#"
                >Vendor Account</a>
                <ul>
                  <li>
                    <router-link to="/dashboard">
                      <p class="mb-0">
                        Dashboard
                      </p>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/product-list">
                      <p class="mb-0">
                        Product List
                      </p>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/edit-product">
                      <p class="mb-0">
                        Edit Product
                      </p>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/profile">
                      <p class="mb-0">
                        Profile
                      </p>
                    </router-link>
                  </li>
                </ul>
              </li>

              <li class="nav-item">
                <router-link
                  class="nav-link"
                  to="/landing"
                >
                  <p class="mb-0">
                    Landing Page
                  </p>
                </router-link>
              </li>-->
            </ul>
          </div>
        </div>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
  import { mapGetters, mapActions, mapState } from 'vuex'
  import HeaderNavbar from '@/views/home/header-navbar'
  import Navbar from '@/views/home/navbar'
  import { directive as onClickaway } from 'vue-clickaway'
  import { i18n } from '@/plugins/i18n'
  import SearchForm from '../../components/SearchForm'
  import AppBarI18n from '../../components/AppBarI18n'
  import AppBarNotifications from '../../components/AppBarNotitifications'
  import ProfileMenu from '../../components/ProfileMenu.vue'
  import Connect from '../../components/Connect.vue'
  import Chat from "@/components/Chat.vue";

  export default {
    name: 'BaseAppBar',
    directives: {
      onClickaway: onClickaway,
    },
    components: {
      Chat,
      Connect,
      ProfileMenu,
      AppBarNotifications,
      AppBarI18n,
      SearchForm,
      HeaderNavbar,
      Navbar,
    },
    props: {
      hideExtra: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapGetters(['getCartProducts', 'unreadNotifications']),
      ...mapState({
        user: 'user',
        login: 'login',
        notifications: 'notifications',
        install_asked: 'install_asked',
        displayNotification: 'display_notification',
      }),
      cartTotal () {
        let total = 0
        this.getCartProducts.forEach(product => {
          total += product.price_without_tax * product.qty
        })
        return total
      },
      createUrl () {
        if (this.user) {
          if (this.user.is_pro_seller) {
            return '/create-product'
          } else {
            return '/create-simple-product'
          }
        } else {
          return '/login'
        }
      },
    },
    data: () => ({
      selectedCategory: null,
      searchType: { name: i18n.t('Articles'), value: 'articles' },
      cartCount: 0,
      signup: true,
      colorNav: false,
      dialog: false,
      isToggleNavbar: false,
      items: [
        { title: 'Car' },
        { title: 'Clothes' },
        { title: 'Electronics' },
        { title: 'Laptop' },
      ],
      searchTypes: [
        { name: i18n.t('Articles'), value: 'articles' },
        { name: i18n.t('Membres'), value: 'members' },
      ],
      itemTwo: ['Foo', 'Bar', 'Fizz', 'Buzz'],
      drawer: false,
      chatDrawer: true,
      group: null,
    }),

    methods: {
      ...mapActions(['addCart', 'removeCart']),
      inboxClicked () {
        // eslint-disable-next-line no-undef
        axios.get('notifications/read')
        this.notifications.map((item) => {
          item.isOpened = true
          item.read_at = new Date()
        })
        this.not_clicked = false

      },
      toggleNavbar () {
        if (this.$route.path == '/') {
          this.isToggleNavbar = false
        } else {
          this.isToggleNavbar = !this.isToggleNavbar
        }
      },
      away () {
        this.isToggleNavbar = false
      },
      removeCartx (item) {
        this.removeCart(item)
      },
      addCartx (item) {
        if (item.qty < item.quantity) {
          item.qty += 1
        }
        this.addCart(item)
      },
    },
  }
</script>
<style lang="scss" scoped>
$z-index-sub-topbar: 2;
$md: 959px;
$z-99: 99;
.create-button {
  span {
    color: white;
  }
  color: white;
  &:hover {
    color: white;
  }
}
.z-999 {
  z-index: 999;
}

.v-list-item {
  min-height: 34px;
}
.top-bar {
  height: 40px;
  display: flex;
  align-items: center;
}
.sale-page-one {
  .sub-topbar {
    &.v-app-bar--is-scrolled {
      position: relative;
      .dropdown-ecommerce {
        display: none;
      }
    }
  }

  .navbar {
    &.v-app-bar--is-scrolled {
      top: 0;
      margin-bottom: 0px;
    }
  }
}
.sale-page-two {
  .sub-topbar {
    &.v-app-bar--is-scrolled {
      position: relative;
      .dropdown-ecommerce {
        display: none;
      }
    }
  }

  .navbar {
    &.v-app-bar--is-scrolled {
      top: 0;
      margin-bottom: 0px;
    }
  }
}
.sub-topbar {
  position: relative;
  box-shadow: none !important;
  width: 100%;
  height: 80px !important;
  z-index: $z-99;
  .dropdown-ecommerce {
    display: none;
  }
  &.v-app-bar--fixed {
    position: unset;
  }
  // z-index: $z-index-sub-topbar;

  &.v-app-bar--is-scrolled {
    position: fixed;
    top: 0;
    box-shadow: none !important;
    .dropdown-ecommerce {
      display: block;
    }
  }
  @media (max-width: $md) {
  }
}

.navbar {
  height: 60px;
  width: 100%;
  z-index: 2;
  box-shadow: rgb(43 52 69 / 10%) 0px 16px 16px -16px !important;
  &.v-app-bar--is-scrolled {
    position: relative;
    top: 64px;
    margin-bottom: 64px;
    box-shadow: rgb(43 52 69 / 10%) 0px 16px 16px -16px !important;
    // box-shadow: none !important;
  }
  @media (max-width: 992px) {
    display: none;
  }
}
.search-bar {
  ::v-deep .v-input__slot {
      border: 1px solid rgb(218, 225, 231);
      background-color: #fff !important;

  }
  .search-bar-dropdown {

     height: 39px;
    position: absolute;
    top: 20px;
    transform: translateY(-50%);
    right: 2px;

    @media (max-width: $md) {
      display: none;
    }
    .dropdown {
      height: 39px;
      border-radius: 0px;
      border-left: 1px solid rgb(218, 225, 231) !important;
      &:last-child {
        border-radius: 22px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-left: 1px solid rgb(218, 225, 231) !important;
      }
      box-shadow: none !important;
    }
  }
  ::v-deep .v-text-field__details {
    display: none;
  }
}

// toggleNavbarButton
.navbar-toggle-dropdown {
  z-index: 98;
  display: none;
  &.open {
    display: block;
    top: 64px;
  }
}
.v-hidden {
  visibility: hidden;
}
.empty-cart-sidebar {
  height: 80vh
}
</style>
